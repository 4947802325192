
import Vue from "vue";

import { Breadcrumb, GarbageStation, Garbage } from "@/interfaces";

import { Route } from "vue-router";

import http from "@/http";

import MyEditor from "@/components/station/MyEditor.vue";

export interface DataType {
  station?: GarbageStation;
}

export default Vue.extend({
  components: {
    MyEditor
  },
  data(): DataType {
    return {
      station: undefined
    };
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/garbage_stations/${to.params.id}`;

    const { data } = await http.get<GarbageStation>(url);

    next((vm: DataType & Vue) => {
      vm.station = data;
    });
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.station == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: this.station.garbage.building.name,
          to: `/building/${this.station.garbage.building_id}`,
          disabled: false
        },
        {
          text: this.station.name,
          disabled: true
        }
      ];
    }
  },
  methods: {
    async save(e: GarbageStation) {
      if (this.station) {
        const url = `garbage_stations/${this.station.id}`;

        await http.put(url, {
          ...e
        });

        await this.$router.push(
          `/building/${this.station.garbage.building_id}/garbage`
        );
      }
    }
  }
});
